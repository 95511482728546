<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 p-0 mb-1 congrats-text">
      CONGRATULATIONS!
    </div>
    <div class="col-12 p-0 mb-1 won-text">
      You've won
    </div>
    <div class="col-12 p-0">
      <div class="row justify-content-center">
        <div class="col-4 col-lg-4 p-0 text-center prize mb-3">
          <div class="row mx-0 justify-content-center">
            <div class="col-12">
              <img :src="prizeInfo.image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mt-3">
      <div class="col-12 p-0">
        <button @click="goBackToLanding" class="arrow-button">
          <span class="arrow-icon">&gt;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Congrats',
  props: ['prize'],
  computed: {
    prizeInfo () {
      if (!this.prize) {
        return {
          name: '',
          image: null
        }
      }
      let name = ''
      let image = null
      switch (this.prize) {
        case 'camp_chair':
          name = 'Camp chair'
          image = require('../assets/images/prizes/bushfire/camp_chair.png')
          break
        case 'power_bank':
          name = 'Power bank'
          image = require('../assets/images/prizes/bushfire/power_bank.png')
          break
        case 'bottle_opener':
          name = 'Bottle opener'
          image = require('../assets/images/prizes/bushfire/bottle_opener.png')
          break
        case 'fleece_blanket':
          name = 'Fleece blanket'
          image = require('../assets/images/prizes/bushfire/fleece_blanket.png')
          break
        case 'instaselfie_light':
          name = 'Instaselfie Light'
          image = require('../assets/images/prizes/bushfire/instaselfie_light.png')
          break
        case 'phone_strap':
          name = 'Phone strap'
          image = require('../assets/images/prizes/bushfire/phone_strap.png')
          break
        case 'neck_fan':
          name = 'Neck fan'
          image = require('../assets/images/prizes/bushfire/neck_fan.png')
          break
      }
      return {
        name,
        image
      }
    }
  },
  methods: {
    goBackToLanding () {
      this.$emit('goBackToLanding')
    }
  }
}
</script>

<style scoped>
.arrow-button {
  border: none;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}

.arrow-button:focus {
  outline: none;
}

.arrow-icon {
  display: inline-block;
  color: #d3202f;
  font-size: 24px; /* Change size as needed */
}
.congrats-text {
  font-size: 2.5rem;
  text-transform: uppercase;
}
.won-text {
  font-size: 4rem;
  text-transform: uppercase;
}
.text {
  color: rgb(252, 181, 51);
  font-size: 1.5rem;
  text-transform: uppercase;
}
.text-sm {
  font-size: 1.25rem;
}
.rotate {
  transform: scaleX(-1);
}
.prize {
  color: rgb(252, 181, 51);
}
@media (min-width: 992px) {
  .text {
    font-size: 2.25rem;
  }
  .text-sm {
    font-size: 1.75rem;
  }
}
</style>
